; (function ($) {
  'use strict'
  // =====================
  // VARS
  // =====================

  // var owlInspiration = $('.inspiration .owl-carousel')
  // var owlEvents =  $('.events .owl-carousel')
  // var player = []
  // var currentVideo
  // var videos = []
  // var itemsPerView = 6
  let $grid = ''
  const locations = [
    {
      lat: 30.282710,
      lng: -98.880510
    },
    {
      lat: 25.626490,
      lng: -102.879280
    },
    {
      lat: 30.282710,
      lng: -98.880510
    },
    {
      lat: 25.764120,
      lng: -103.268530
    },
    {
      lat: 18.109370,
      lng: -96.134840
    }
  ]

  // =====================
  // READY
  // =====================

  $(document).ready(appInit)

  // =====================
  // APP
  // =====================

  function appInit() {
    appSet()
    appEvents()
    // appSetMarginTop()
  }

  function appSet() {
    setParallax()
    setSidebar()

    if (page === 'locations') {
      map(0)
    } else if (page === 'news') {
      appIsotope()
    } else if (page === 'home') {
      const height = $(window).height()
      const heightHeader = $('.site-header').outerHeight()

      $('.slider-small .image-sm').height(height - heightHeader)
    }
  }

  function appEvents() {
    $('.open-sidebar').on('click', function () {
      $('.sidebar').addClass('opened')
      $('.sidebar .content').removeClass('hidden-menu')
      $('body').addClass('no-scroll')
    })

    $('.sidebar-close').on('click', function () {
      $('.sidebar .content').addClass('hidden-menu')
      setTimeout(() => {
        $('.sidebar').removeClass('opened')
        $('body').removeClass('no-scroll')
      }, 500)
    })

    $('.sidebar .overlay').on('click', function () {
      $('.sidebar .content').addClass('hidden-menu')
      setTimeout(() => {
        $('.sidebar').removeClass('opened')
        $('body').removeClass('no-scroll')
      }, 500)
    })

    $('.sidebar .content li.item').on('click', function () {
      if ($(this).hasClass('isOpen')) {
        $(this).removeClass('isOpen')
      } else {
        $('.sidebar .content li.item').removeClass('isOpen')
        $(this).addClass('isOpen')
      }
    })

    $('.menu-item-has-children a').on('click', function () {
      $(this).parent().toggleClass('open')
    })

    $('.address').on('click', function () {
      const id = Number($(this).data('id'))
      map(id)
    })

    $('.load-more').on('click', function () {
      const section = `.${$(this).parent().data('section')}`

      $(this).addClass('loading').text('loading...')

      if (isMobile()) {
        getItems(2, section)
      } else {
        getItems(6, section)
      }
    })

    $('.submit-message').on('click', function (e) {
      e.preventDefault()
      submitForm()
    })

    $('.form-control').on('input', function () {
      var input = $(this).attr('id')
      var placeholder = ''

      switch (input) {
        case 'name':
          placeholder = 'Your full name'
          break
        case 'email':
          placeholder = 'Your e-mail address'
          break
        case 'subject':
          placeholder = 'Subject'
          break
        case 'message':
          placeholder = 'Leave your message'
          break
      }

      $(this).attr('placeholder', placeholder).removeClass('error')
    })

    $('.contact #subject').on('change', function () {
      if ($(this).hasClass('error')) {
        $(this).removeClass('error')
      }
    })

    $(window).scroll(function (e) {
      e.preventDefault()

      if (!isMobile()) {
        appToolbar()
      }
    })

    // When the user scrolls the page, execute myFunction
    // window.onscroll = function () { myFunction() }
  }

  function setSidebar() {
    const height = $(window).height()

    $('.sidebar').height(height)
  }

  function submitForm() {
    const name = $('#name').val()
    const email = $('#email').val()
    const subject = $('#subject').val()
    const message = $('#message').val()
    // console.log(name, email, subject, message, subject !== '0')

    if (name !== '') {
      if (email !== '') {
        if (validateEmail(email)) {
          if (subject !== '') {
            // if (subject !== '0') {
            $('.contact #subject').removeClass('error')

            if (message !== '') {
              $('.submit-message')
                .removeClass('state-await')
                .addClass('state-sending')
                .text('Sending...')

              setTimeout(function () {
                delivery(name, email, subject, message)
              }, 2000)
            } else {
              $('.contact #message')
                .attr('placeholder', 'Please provide your message')
                .addClass('error')
                .focus()
            }
          } else {
            $('.contact #subject')
              .attr('placeholder', 'Please provide the subject')
              .addClass('error')
              .focus()
          }
        } else {
          $('.contact #email')
            .val('')
            .attr('placeholder', 'Please provide an valid email address')
            .addClass('error')
            .focus()
        }
      } else {
        $('.contact #email')
          .attr('placeholder', 'Please provide your email')
          .addClass('error')
          .focus()
      }
    } else {
      $('.contact #name')
        .attr('placeholder', 'Please provide your name')
        .addClass('error')
        .focus()
    }
  }

  function delivery(name, email, subject, message) {
    const url = 'https://rioslumstudio.com/newmail.php'
    // const url = '/newmail.php'
    $.post(url, {
      name: name,
      email: email,
      subject: subject,
      message: message
    }, function (response, status) {
      if (response.http_response_code === 200) {
        $('.submit-message')
          .removeClass('state-sending')
          .addClass('state-success')
          .text('Your message was sent')

        setTimeout(function () {
          $('.contact #name').val('')
          $('.contact #email').val('')
          $('.contact #subject').val('')
          // $('.contact #subject').val('0').trigger('change')
          $('.contact #message').val('')

          $('.submit-message')
            .removeClass('state-success')
            .addClass('state-await')
            .text('Contact Us Now')
        }, 2000)
      } else {
        $('.submit-message')
          .removeClass('state-sending')
          .addClass('state-fail')
          .text('Sorry, there was an error. Please try again later')

        setTimeout(function () {
          $('.submit-message')
            .removeClass('state-fail')
            .addClass('state-await')
            .text('Contact Us Now')
        }, 2000)
      }
    }
    )
  }

  function map(id) {
    const latLon = locations[id]
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 10,
      center: latLon,
      styles: [{ 'elementType': 'geometry', 'stylers': [{ 'color': '#f5f5f5' }] }, { 'elementType': 'labels.icon', 'stylers': [{ 'visibility': 'off' }] }, { 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#616161' }] }, { 'elementType': 'labels.text.stroke', 'stylers': [{ 'color': '#f5f5f5' }] }, { 'featureType': 'administrative.land_parcel', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#bdbdbd' }] }, { 'featureType': 'poi', 'elementType': 'geometry', 'stylers': [{ 'color': '#eeeeee' }] }, { 'featureType': 'poi', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#757575' }] }, { 'featureType': 'poi.park', 'elementType': 'geometry', 'stylers': [{ 'color': '#e5e5e5' }] }, { 'featureType': 'poi.park', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#9e9e9e' }] }, { 'featureType': 'road', 'elementType': 'geometry', 'stylers': [{ 'color': '#ffffff' }] }, { 'featureType': 'road.arterial', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#757575' }] }, { 'featureType': 'road.highway', 'elementType': 'geometry', 'stylers': [{ 'color': '#dadada' }] }, { 'featureType': 'road.highway', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#616161' }] }, { 'featureType': 'road.local', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#9e9e9e' }] }, { 'featureType': 'transit.line', 'elementType': 'geometry', 'stylers': [{ 'color': '#e5e5e5' }] }, { 'featureType': 'transit.station', 'elementType': 'geometry', 'stylers': [{ 'color': '#eeeeee' }] }, { 'featureType': 'water', 'elementType': 'geometry', 'stylers': [{ 'color': '#c9c9c9' }] }, { 'featureType': 'water', 'elementType': 'labels.text.fill', 'stylers': [{ 'color': '#9e9e9e' }] }],
      disableDefaultUI: true,
      scrollwheel: false,
      draggable: false
    })
    const marker = new google.maps.Marker({
      position: latLon,
      map: map
    })
  }

  function appToolbar() {
    var header = document.getElementById('masthead')
    var pageYOffset = window.pageYOffset

    if (pageYOffset > header.clientHeight) {
      $('#masthead').addClass('sticky')
      $('.site').css('paddingTop', header.clientHeight)
    } else if (pageYOffset === 0) {
      $('#masthead').removeClass('sticky')
      $('.site').css('paddingTop', '0')
    }
  }

  function setParallax() {
    var bgPosition = $(window).width() <= 768 ? 0.1 : 0.2

    // $('[data-parallax="1"]').parallax('100%', bgPosition, false)
    // $('[data-parallax="2"]').parallax('100%', bgPosition, false)

    if (isMobile()) {
      $('[data-parallax="1"]').parallax('100%', bgPosition, false)
      $('[data-parallax="2"]').parallax('100%', bgPosition, false)
    } else {
      $('[data-parallax="1"]').parallax('100%', bgPosition, false)
      $('[data-parallax="2"]').parallax('100%', bgPosition, false)
    }

    // if ($(window).width() < 576) {
    //   $('[data-parallax="1"]').parallax('100%', 0.1, false)
    //   $('[data-parallax="2"]').parallax('100%', 0.1, false)
    // } else {
    //   jarallax(document.querySelectorAll('.jarallax'), {
    //     speed: -0.2,
    //     type: 'scroll'
    //     // speed: (isMobile()) ? -1.0 : -0.2,
    //     // type: (isMobile()) ? 'scale' : 'scroll',
    //     // speed: 0,
    //     // disableParallax: /iPad|iPhone|iPod|Android/,
    //     // disableVideo: /iPad|iPhone|iPod|Android/
    //     // disableParallax: function () {
    //     //   if (
    //     //     window.innerWidth < 576 ||
    //     //     /iPad|iPhone|iPod|Android/.test(navigator.userAgent)
    //     //   ) {
    //     //     return true
    //     //   } else {
    //     //     return false
    //     //   }
    //     // },
    //     // disableVideo: function () {
    //     //   if (
    //     //     window.innerWidth <= 768 ||
    //     //     /iPad|iPhone|iPod|Android/.test(navigator.userAgent)
    //     //   ) {
    //     //     return true
    //     //   } else {
    //     //     return false
    //     //   }
    //     // }
    //   })
    // }
  }

  function getItems(itemsPerView, el) {
    var itemsLen = $(el).find('.element-item').length
    var itemsVisBeforeLen = $(el).find('.element-item:visible').length
    var items = itemsVisBeforeLen + itemsPerView <= itemsLen
      ? itemsVisBeforeLen + itemsPerView
      : itemsLen
    var target = el + ' .element-item:nth-child(' + (items - itemsPerView + 1) + ')'
    var btn = $('.loading')

    $(el).find('.element-item:lt(' + items + ')').addClass('show')

    $grid.isotope({
      filter: '.show'
    })

    setTimeout(function () {
      var itemsVisAfterLen = $(el).find('.element-item:visible').length

      if (itemsLen === itemsVisAfterLen) {
        btn.removeClass('loading').addClass('disabled').hide()
      } else {
        btn.removeClass('loading').text('More News')
      }
    }, 500)
  }

  function appIsotope() {
    $grid = $('.grid').isotope({
      itemSelector: '.element-item',
      layoutMode: 'fitRows',
      filter: '.show'
    })

    // var filterFns = {
    //   numberGreaterThan50: function () {
    //     var number = $(this).find('.number').text()
    //     return parseInt(number, 10) > 50
    //   },
    //   ium: function () {
    //     var name = $(this).find('.name').text()
    //     return name.match(/ium$/)
    //   }
    // }

    $('.events, .blog-posts').find('.filters-select').on('change', function () {
      $grid.isotope({
        filter: $(this).val()
      })
    })

    $('.filters').on('click', 'button', function () {
      const filterValue = $(this).attr('data-filter')
      // const section = `.${$(this).parent().data('section')}`

      // filterValue = filterFns[ filterValue ] || filterValue

      if (filterValue !== '.show') {
        $('.load-more').hide()
      } else {
        $('.load-more').show()
      }

      $grid.isotope({
        filter: $(this).attr('data-filter')
      })
    })

    $('.filters').each(function (i, filters) {
      const $filters = $(filters)
      $filters.on('click', 'button', function () {
        $filters.find('.is-checked').removeClass('is-checked')
        $(this).addClass('is-checked')
      })
    })
  }

  // function appLoad () {
  //   $.getJSON(json, appLoaded)
  // }

  // function appLoaded (response) {
  //   entries = response
  // }

  // =====================
  // HELPERS
  // =====================
  function isMobile() {
    return $(window).width() < 768
  }

  function isInArray(value, array) {
    return array.indexOf(value) > -1
  }

  function isSmallScreen() {
    return $(window).width() < 768
  }

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  function goToByScroll(el) {
    $('html,body').animate({ scrollTop: $(el).offset().top }, 'slow')
  }

  function toTarget($anchor, time) {
    var scrollTop = 0
    var nav = $('.site-header')

    var navHeight = nav.height()

    if (typeof $anchor === 'undefined') {
      scrollTop = 0
    } else {
      if (nav.hasClass('fixed')) {
        scrollTop = $($anchor).offset().top - navHeight
      } else {
        scrollTop = $($anchor).offset().top - navHeight * 2
      }
    }

    $('html, body').stop().animate({
      scrollTop: scrollTop
    }, time || 1500, 'easeInOutExpo', function () {
      if (isMobile()) {
        $('.sidebar-menu').addClass('hidden-menu')
      }
    })
  }

  function scrollOnLoad() {
    $('html, body').animate(
      { scrollTop: $('#barra-globocom').height() + $('.g1-header').height() },
      600
    )
    return false
  }

  function scrollToUp() {
    $('html, body').animate(
      {
        scrollTop: $('#barra-globocom').height() +
          $('.g1-header').height() +
          $('#header').height()
      },
      600
    )
    return false
  }

  function showBtnScrollUp() {
    $(this).scrollTop() > 700
      ? $('.scroll-up').fadeIn()
      : $('.scroll-up').fadeOut()
  }

  function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
})(jQuery)
